import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';

import icon from "../../assets/images/icons/forward-primary.svg";
import ContentRow from '../common/ContentRow';
import CenteredContent from '../common/CenteredContent';

const OpenPositions = ({ className }) => {
  const [positions, setPositions] = useState([]);

  useEffect(() => {
    const fetchPositions = async () => {
      const response = await fetch('https://lawadvisor.recruitee.com/api/offers')
      const { offers } = await response.json()
      setPositions(offers)
    }

    fetchPositions()
  }, [])

  return (
    <ContentRow id="open-positions" className={`${className || ''} position-relative pt-120 open-positions override`}>
      <CenteredContent
        background={true}
        sectionTitle="Open positions"
      >
        {positions.sort((a, b) => {
          const nameA = a.title.toUpperCase();
          const nameB = b.title.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          return 0;
        }).map((o, i, arr) => {
          const location = o.location.replace(" job", "")
          const city = o.city.replace(" city", "")

          let className = ""
          if (arr.length - 1 === i) {
            className = "position-spacing-last"
          } else {
            className = "border-bottom position-spacing"
          }

          return (
            <div key={o.slug} className={className}>
              <a href={o.careers_url}>
                <h4 className="text-primary">{o.title} <img src={icon} /></h4>
              </a>
              <div className="position-spacing open-position-location text-muted">
                <span>{location}</span>
                <span className="pl-1 pr-1">•</span>
                <span>{city}</span>
              </div>
            </div>
          )
        })}
      </CenteredContent>
    </ContentRow>
  )
}

export default OpenPositions;
