import React from 'react';
import forward from '../../assets/images/icons/forward.svg';

function FullWidthImage({ className, text, location }) {
  return (
    <div className={`${className || ''} d-flex flex-row`}>
      <a href={location} className="">{text}</a>
      <img className="ml-1" src={forward} />
    </div>
  );
}

export default FullWidthImage;