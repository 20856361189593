import React from 'react';

import background from '../../assets/images/gifs/graphic-lines.png';
import img1 from '../../assets/images/StockPhotos/optimized-photos/career-page/career-01.png';
import img2 from '../../assets/images/StockPhotos/optimized-photos/career-page/career-02.png';
import img3 from '../../assets/images/StockPhotos/optimized-photos/career-page/career-03.png';
import img4 from '../../assets/images/StockPhotos/optimized-photos/career-page/career-04.png';

function Culture({ className }) {
  return (
    <div>
      <div className={`culture-widget override d-none d-sm-none d-md-block d-xl-block ${className} || ''`} style={{ backgroundImage: `url(${background})`, backgroundPosition: "center" }}>
        <div className="row">
          <div className="col-xl-2 col-md-2 col-sm-12">
            <img className="img1 rounded" src={img1} />
          </div>
          <div className="col-xl-2 col-md-2 col-sm-12">
            <img className="img2 rounded" src={img2} />

          </div>
          <div className="col-4">
            <h3 className="text-center pt-3">Culture</h3>
            <h4 className="text-center pt-3 text-muted">
              At LawAdvisor, and like all great companies, people are valued over processes. Our people operate with high integrity and respect for each other and our clients, and this ensures that decisions are made to the benefit of all stakeholders.
            </h4>
          </div>
          <div className="col-xl-2 col-md-2 col-sm-12">
            <img className="img3 rounded" src={img3} />
          </div>
          <div className="col-xl-2 col-md-2 col-sm-12">
            <img className="img4 rounded" src={img4} />

          </div>
        </div>
      </div>
      <div className="culture-widget-small override full-width d-md-none d-xl-none d-sm-block mt-md-0">
        <div className="row">
          <div className="col-sm-12 ml-3">
            <div className="flex-container">
              <img className="img1 rounded " src={img1} />
              <img className="img2 rounded" src={img2} />
            </div>
          </div>
          <div className="col-sm-12 ml-3 mr-3">
            <h3 className="text-left text-md-center pt-5 pt-sm-80 mb-sm-32">Culture</h3>
            <h4 className="text-left text-md-center pt-0 pt-sm-3 pb-0 pb-sm-5 mb-sm-80 text-muted">
            At LawAdvisor, and like all great companies, people are valued over processes. Our people operate with high integrity and respect for each other and our clients, and this ensures that decisions are made to the benefit of all stakeholders.
            </h4>
          </div>
          <div className="col-sm-12">
            <div className="flex-container">
              <img className="img3 rounded mr-4" src={img3} />
              <img className="img4 rounded" src={img4} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Culture;