import React from 'react';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';

function VideoParallax({ className, videos, directionOffset }) {
  const showVideos = videos.concat(videos.slice(0, 2))
  return (
    <div className={`image-parallax-widget ${className} || ""`}>
      <ParallaxProvider>
        <Parallax
          x={directionOffset}
        >
          <div className="d-flex">
            {showVideos.map(video =>
              <video
                className="position-relative m-2 team-video"
                autoPlay
                muted
                loop
                width="400"
                height="225"
                onMouseOver={event => event.target.pause()}
                onMouseOut={event => event.target.play()}
              >
                <source src={video} type="video/mp4" />
              </video>
            )}
          </div>
        </Parallax>
      </ParallaxProvider>
    </div>
  )
};

export default VideoParallax