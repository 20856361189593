import React, { useRef } from 'react';

import FullWidthImage from '../components/common/FullWidthImage';
import Layout from '../components/layout/Layout';
import LatestNews from '../components/news/LatestNews';
import PageTitleSection from '../components/common/PageTitleSection';
import LinkForward from '../components/buttons/LinkForward';
import CareerHero from '../assets/images/StockPhotos/optimized-photos/career-page/career-hero.png';
import CenteredContent from '../components/common/CenteredContent';
import OpenPositions from '../components/job-post/OpenPositionsV2';
import Culture from '../components/job-post/Culture';
import ContentRow from '../components/common/ContentRow';
import IconContent from '../components/careers/IconContent';

import winAndGrow from '../assets/images/icons/win-and-grow.svg';
import nothingIsSmall from '../assets/images/icons/nothing-is-small.svg';
import diamond from '../assets/images/icons/diamond.svg';
import improvement from '../assets/images/icons/improvement.svg';
import teamwork from '../assets/images/icons/teamwork.svg';
import loompas from '../assets/images/icons/loompas.svg';
import compensation from '../assets/images/icons/compensation.svg';
import learning from '../assets/images/icons/learning.svg';
import OurTeamSection from '../components/careers/OurTeamSection';
import lines from '../assets/images/careers/lines.png';

function Careers() {
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();
  return (
    <Layout
      title="Careers"
      description="At LawAdvisor, our people and their development come first. We believe that the company’s success depends on the ability of our people to learn together and produce new ideas."
      image="/metaimages/meta-image-la-ventures.png"
    >
      <PageTitleSection
        haveArrow
        executeScroll={executeScroll}
      >
        <h2 className="mb-16">Careers</h2>
        <LinkForward location="#open-positions" text="See open positions" />
      </PageTitleSection>
      <FullWidthImage img={CareerHero} />
      <ContentRow className="fit-width content-row override">
        <div ref={myRef} className="col-12 col-sm-9">
          <ContentRow className="pt-50 pb-sm-0 pt-sm-0">
            <CenteredContent
              sectionTitle="Our core values"
            >
              <div className="paragraph">
                <h4 className="mb-24">
                  At LawAdvisor, our people and their development come first.
                  We believe that the company’s success depends on the ability
                  of our people to learn together and produce new ideas.
                </h4>
              </div>
            </CenteredContent>
            <CenteredContent>
              <IconContent
                icon={winAndGrow}
                title="Win and grow together"
                text="Strive to become the best in your field and always seek opportunities for growth. You are not afraid of challenges or afraid to fail because you have a growth mindset."
              />
              <IconContent
                icon={nothingIsSmall}
                title="Nothing is too small"
                text="You’re never too big to do the small things."
              />
              <IconContent
                icon={diamond}
                title="Pressure makes diamonds"
                text="As a team, we train to win. You are not scared of pressure and embrace challenges and never lose your resolve."
              />
              <IconContent
                icon={improvement}
                title="Continuous improvement"
                text="As a leader in the legal-technology space, we will continue pushing through the boundaries to create breakthrough legal products."
              />
              <IconContent
                icon={teamwork}
                title="Teamwork makes the dream work"
                text="We don’t concern ourselves with office politics. There are plenty of opportunities to earn, learn and grow that we can exploit when we tackle each challenge as a unit."
              />
              <IconContent
                icon={loompas}
                className="last-icon-content"
                title="No Oompa Loompas"
                text="Although we're united with a strong vision and working towards shared goals, we encourage you to be yourself. Our team is diverse, and that is how we are able to continue to think outside the box and come up with the most creative solutions to entrenched problems."
              />
            </CenteredContent>
          </ContentRow>
        </div>
      </ContentRow>
      <Culture />
      <OurTeamSection />
      <ContentRow className="fit-width bottom-row override">
        <div className="col-12 col-sm-9">
          <ContentRow>
            <CenteredContent
              sectionTitle="Working at LawAdvisor"
            >
              <div className="paragraph mb-64 mb-sm-24">
                <h4 className="mb-0">
                  Amazing people have joined LawAdvisor to make it what it is today.
                  To show our appreciation, here are some of our company perks that
                  we provide our people as a way for us to say ‘thank-you’.
                </h4>
              </div>
            </CenteredContent>
            <CenteredContent>
              <IconContent
                icon={compensation}
                title="Competitive compensation"
                text="We strive to always provide industry market rates."
              />
              <IconContent
                icon={learning}
                title="Learning and development"
                text="Whether by way of conferences, online courses, or further study, we’re here to support your personal and professional growth."
              />
              <IconContent
                icon={teamwork}
                title="Team and company off-sites"
                text="While Zoom and Slack is our operating system, our company retreats allow us to create long lasting bonds with each other and to feel human."
              />
            </CenteredContent>
          </ContentRow>
          <OpenPositions />
        </div>
      </ContentRow>
      <div className="container-fluid px-0 mx-0 bg-white pb-5">
        <div className="fit-width">
          <div className="job-banner">
            <div className="content">
              <h3>Can&apos;t find the job you want?</h3>
              <p>
                No problem! We&apos;re always on the lookout for exceptional individuals to join
                our team and we&apos;d love to hear from you!
                <br />
                <br />
                Email us at
                {' '}
                <a href="mailto:talent@lawadvisor.com">talent@lawadvisor.com</a>
                {' '}
                and let&apos;s see how we can work together.
              </p>
            </div>
            <img src={lines} className="lines" alt="background" />
          </div>
        </div>
      </div>
      <LatestNews className="job-post-section fit-width" />
    </Layout>
  );
}

export default Careers;
