import React from 'react'
import VideoParallax from './VideoParallax';

import alex from "../../assets/videos/la-ventures--alex-trend.mp4";
import anna from "../../assets/videos/la-ventures--anna-sesbreno.mp4";
import aris from "../../assets/videos/la-ventures--aris-acoba.mp4";
import brennan from "../../assets/videos/la-ventures--brennan-ong.mp4";
import chau from "../../assets/videos/la-ventures--chau-nguyen.mp4";
import grietha from "../../assets/videos/la-ventures--grietha-putri.mp4";
import jasmin from "../../assets/videos/la-ventures--jasmin-brown.mp4";
import jasmine from "../../assets/videos/la-ventures--jasmine-chiu.mp4";
import john from "../../assets/videos/la-ventures--john-ugalino.mp4";
import luke from "../../assets/videos/la-ventures--luke-evans.mp4";
import ram from "../../assets/videos/la-ventures--ram-masinas.mp4";
import raph from "../../assets/videos/la-ventures--raph-cardona.mp4";
import theo from "../../assets/videos/la-ventures--theo-verge.mp4";

function OurTeamSection() {
  let videos = [alex, anna, aris, brennan, chau, grietha, jasmin, jasmine, john, luke, ram, raph, theo]

  const shuffleArray = (array) => {
    var currentIndex = array.length, temporaryValue, randomIndex;
      while (0 !== currentIndex) {

      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

  videos = shuffleArray(videos)

  return (
    <div className="container-fluid mx-0 px-0 py-5 bg-primary our-team override">
      <div className="law-section fit-width">
        <h2 className="text-white">Our team</h2>
        <p className="content text-white">
          We&apos;re diverse, forward-thinkers working collaboratively across the globe.
          <br/>
          We know our tech and we know our clients.
          <br/>
          We are LawAdvisor.
        </p>
      </div>
      <div className="d-none d-md-block _h-450">
        <div className="position-absolute full-width overflow-hidden">
          <VideoParallax directionOffset={[-30, 2]} videos={videos.slice(0, 6)} />
          <VideoParallax directionOffset={[2, -30]} videos={videos.slice(6)}/>
        </div>
      </div>
      <div className="row d-block d-md-none teamvideo-section">
        {videos.map(video =>
          <div className="col-sm-12 text-center teamvideo-container">
            <video
              className="position-relative pb-2 team-video"
              autoPlay
              muted
              loop
            >
              <source src={video} type="video/mp4" />
            </video>
          </div>
        )}
      </div>
    </div>
  )
}

export default OurTeamSection;
